import Vue from 'vue';
import Vuex from 'vuex';
import { commonStore } from '@/utils/store.js';
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    ...commonStore.state,
    _foodTopicId: '4a7a9602-f47a-4d6e-98fe-a5a3006f8453',
  },
  getters: {
    ...commonStore.getters,
    foodTopicId(state) {
      return state._foodTopicId;
    },
  },
  mutations: {
    ...commonStore.mutations,
  },
  actions: {
    ...commonStore.actions,
  },
});

export { store };
